import React, { useState, useEffect, useRef } from 'react'
import background from 'vanta/dist/vanta.net.min.js'
import messages from '../messages.json';

import './Main.css';

import facebookIcon from '../assets/facebook.png';
import instagramIcon from '../assets/instagram.png';
import linkedinIcon from '../assets/linkedin.png';
import youtubeIcon from '../assets/youtube.png';
import logo from '../assets/logo.png';
import disconnected from '../assets/disconnect.jpg';

import ListItem from './ListItem';

function Main() {
  const [vantaEffect, setVantaEffect] = useState(0)
  const myRef = useRef(null)

  const [language, setLanguage] = React.useState(
      navigator.language.split(/[-_]/)[0]
  );

  function openDevozPage() {
    var win = window.open('https://devoz.com.br', '_blank');
    win.focus();
  }

  function openOZmap() {
    fetch("https://" + window.location.hostname + ":9994").then(function () {
      window.location = "https://" + window.location.hostname + ":9994";
    }).catch(function () {
      window.location = "http://" + window.location.hostname + ":9090";
    })
  }

  function openLoki() {
    fetch("https://" + window.location.hostname + ":9443").then(function () {
      window.location = "https://" + window.location.hostname + ":9443";
    }).catch(function () {
      window.location = "http://" + window.location.hostname + ":8081";
    })
  }

  function openOZMob() {
    var win = window.open('https://ozmap.com.br/ozmob', '_blank');
    win.focus();
  }

  function openSupport() {
    var win = window.open('https://devoz.freshdesk.com/support/home', '_blank');
    win.focus();
  }

 function openFacebook(){
   var win = window.open('https://www.facebook.com/devOZmap/', '_blank')
   win.focus();
 }

 function openInstagram(){
  var win = window.open('https://www.instagram.com/devozmap/', '_blank')
  win.focus();
}

function openLinkedIn(){
  var win = window.open('https://www.linkedin.com/company/ozmap/', '_blank')
  win.focus();
}

function openYoutube(){
  var win = window.open('https://www.youtube.com/channel/UCKfDSVnwO64IUvzZMPZFtSg/featured', '_blank')
  win.focus();
}
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(background({
        el: myRef.current,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        scale: 1.00,
        scaleMobile: 1.00,
        points: 10.00,
        maxDistance: 19.00,
        spacing: 15.00,
        color: 0x23A455,
        backgroundColor: 0x121214
      }))
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])

  return (
    <div className="main-container" ref={myRef}>
      <header className="header">
            <h1>
            OZMap
            </h1>
        <a href="">
          <img src={logo} onClick={openDevozPage} className="logo" alt="logoImage" />
        </a>
      </header>

      <div className="informPanel">
        <img className="disconnectedImg" src={disconnected} alt="DisconectedImage" />
        <br/>
        <h2 className="title">{messages[language].home_payment_title}</h2>
        <br/><br/>
        <h3>{messages[language].home_payment_subtitle}</h3>

        <br/>
        <div dangerouslySetInnerHTML={{ __html: messages[language].home_payment_message }}></div>
        <br/>
        <br/> <div dangerouslySetInnerHTML={{ __html: messages[language].home_payment_contacts_whatsapp }}></div>
        <br/> <div dangerouslySetInnerHTML={{ __html: messages[language].home_payment_contacts_email }}></div>

        <br/>
        <div dangerouslySetInnerHTML={{ __html: messages[language].home_payment_client_access_area }}></div>
      </div>

      <footer className="footer">
        <img src={facebookIcon} onClick={openFacebook} alt="FacebookLogo"/>
        <img src={instagramIcon} onClick={openInstagram} alt="InstagramLogo"/>
        <img src={linkedinIcon}  onClick={openLinkedIn} alt= "LinkedInLogo"/>
        <img src={youtubeIcon} onClick={openYoutube} alt="YoutubeLogo"/>
      </footer>
    </div>
  );
}

export default Main;
