import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Main from './components/Main';

const xhr = new XMLHttpRequest();
xhr.open("GET", "https://nr-erp.ozmap.com.br/api/v1/portais/inadimplente?cliente="+window.location.host);
xhr.send();

ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById('root')
);